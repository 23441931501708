import { query, redirect } from '@solidjs/router';
import { getRequestEvent, isServer } from 'solid-js/web';
import { z } from 'zod';
import { ttlCache } from './cache';
import { getApiClient } from './client';
import { ApiError, UnknownApiError } from './errors';
import { EndpointByMethod } from './__generated__/validators';
import type { IError } from './errors';
import type { paths } from './__generated__/database';
import type { FetchResponse, FetchOptions } from 'openapi-fetch';
import type { FilterKeys, PathsWithMethod } from 'openapi-typescript-helpers';

export type GetOptions = {
	requireAuth?: boolean;
	redirect401?: boolean;
};

export function cachedGet<
	Path extends PathsWithMethod<paths, 'get'>,
	Res extends NonNullable<
		FetchResponse<
			'get' extends infer T
				? T extends 'get'
					? T extends keyof paths[Path]
						? paths[Path][T]
						: Record<string, string>
					: never
				: never,
			'json',
			'application/json'
		>['data']
	>,
>(
	path: Path,
	rest?: Omit<FetchOptions<FilterKeys<paths[Path], 'get'>>, 'params'>,
	opts?: GetOptions,
	key?: string,
): (params: FetchOptions<FilterKeys<paths[Path], 'get'>>['params']) => Promise<Res> {
	const cacheFn = query(async (params: FetchOptions<FilterKeys<paths[Path], 'get'>>['params']): Promise<Res> => {
		function redirect401() {
			if (opts?.redirect401) {
				return;
			}
			const url = isServer ? new URL(getRequestEvent()!.request.url) : new URL(window.location.toString());
			if (url.pathname.startsWith('/auth')) {
				return;
			}
			if (!url.searchParams.get('redirect')) {
				url.searchParams.set('redirect', url.pathname);
			}
			return redirect(`/auth/login?${url.searchParams.toString()}`);
		}

		if (ttlCache?.has(cacheFn.keyFor(params))) {
			return ttlCache!.get<Res>(cacheFn.keyFor(params))!;
		}

		const client = getApiClient();
		if (opts?.requireAuth && !client.isAuthenticated) {
			const url = isServer ? new URL(getRequestEvent()!.request.url) : new URL(window.location.toString());
			if (!url.searchParams.get('redirect')) {
				url.searchParams.set('redirect', url.pathname);
			}
			throw redirect401();
		}

		const validator = EndpointByMethod['get'][path];
		if (validator.parameters instanceof z.ZodObject) {
			try {
				validator.parameters.parse(params);
			} catch {
				throw new UnknownApiError({});
			}
		}

		const res = await client.GET(path, { ...rest, params } as FetchOptions<FilterKeys<paths[Path], 'get'>>);

		if (res.error) {
			if (res.response.status === 401 && opts?.redirect401 !== false) {
				throw redirect401();
			}
			if (res.response.status === 401 && opts?.redirect401 === false) {
				return undefined as Res;
			}
			throw new ApiError(res.response.status, res.error as unknown as IError);
		}
		if (!res.data) {
			throw new UnknownApiError();
		}

		ttlCache?.set(cacheFn.keyFor(params), res.data);

		return res.data as Res;
	}, key ?? path);

	return cacheFn;
}
